<template>
  <el-dialog
      :title="title"
      :visible.sync="createDialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :append-to-body="true"
      v-drag-dialog
      @close="close">
    <div class="pageContainer">
      <el-form label-position="right"
               :model="formData"
               :rules="rules"
               ref="policyForm"
               label-width="65px"
               element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-form-item label="标题" prop="title">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="类型" prop="type">
              <!--              <el-input v-model="formData.type"></el-input>-->
              <el-select v-model="formData.type" style="width: 100%">
                <el-option value="news" label="咨询"></el-option>
                <el-option value="guide" label="指南"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封面" prop="cover">
              <!--              <el-input v-model="formData.cover"></el-input>-->
              <file-upload-btn v-if="createDialogVisible" @uploadSuccess="handleUploadSuccess"></file-upload-btn>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发布者" prop="publisher">
              <el-input v-model="formData.publisher"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="序号" prop="orderNo">
              <el-input-number :precision='0'
                               style="width: 100%"
                               :controls="false"
                               v-model="formData.orderNo"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="内容" prop="content">
          <wang-editor v-model="formData.content"
                       :show-status="showStatus"
                       v-if="createDialogVisible"
                       :custom-config="{ height: 400 }"></wang-editor>
        </el-form-item>
        <el-form-item label="内容链接" prop="url">
          <el-input v-model="formData.url"></el-input>
        </el-form-item>
      </el-form>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="trySubmitPolicy" :loading="addingLoading">确认提交</el-button>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import WangEditor from 'modules/components/WangEditor/index.vue'
import FileUploadBtn from 'modules/components/fileUploadBtn/index.vue'
import { taskConfig as CK_TASK_CONFIG } from 'config/ckEditorConfig'
import { rules } from '../option'

function resetPolicy() {
  return {
    title: '',
    publisher: '',
    cover: '',
    type: '',
    orderNo: undefined,
    content: '',
    url: ''
  }
}

export default {
  name: 'addOrEditPolicy',
  components: {
    WangEditor,
    FileUploadBtn
  },
  data() {
    return {
      optType: 'add',
      createDialogVisible: false,
      showStatus: false,
      callback: null,
      formData: resetPolicy(),
      rules,
      CK_TASK_CONFIG,
      addingLoading: false
    }
  },
  computed: {
    title() {
      return this.optType === 'add' ? '新增资讯' : '编辑资讯'
    }
  },
  methods: {
    ...mapActions(['savePolicy', 'getPolicyById', 'updatePolicy']),
    show(options) {
      this.optType = options.optType
      this.callback = options.callback
      this.showStatus = true
      if (this.optType === 'edit') {
        this.tryGetPolicyById(options.detail.id)
        this.formData = options.detail
      } else {
        this.formData = resetPolicy()
      }
      this.createDialogVisible = true
      this.$nextTick(() => {
        this.$refs.policyForm.clearValidate()
      })
    },
    close() {
      this.showStatus = false
      this.createDialogVisible = false
    },
    handleUploadSuccess(url) {
      this.formData.cover = url
    },
    tryGetPolicyById(id) {
      this.getPolicyById(id).then(res => {
        this.formData = res || this.formData
      })
    },
    tryUpdatePolicy() {
      this.addingLoading = true
      this.updatePolicy(this.formData).then(() => {
        this.$message.success('操作成功')
        this.callback && this.callback()
        this.close()
      }).finally(() => {
        this.addingLoading = false
      })
    },
    trySubmitPolicy() {
      if (this.addingLoading) {
        return
      }
      this.$refs.policyForm.validate((valid) => {
        if(valid) {
          if (!this.formData.content && !this.formData.url) {
            this.$message.warning('内容和内容链接不能同时为空！')
            return
          }
          const api = this.optType === 'add' ? this.trySavePolicy : this.tryUpdatePolicy
          api()
        }
      })
    },
    trySavePolicy() {
      this.addingLoading = true
      this.savePolicy(this.formData).then(() => {
        this.$message.success('提交成功')
        this.resetPolicy()
        this.callback && this.callback()
        this.close()
      }).finally(() => {
        this.addingLoading = false
      })
    },
    resetPolicy() {
      this.formData = resetPolicy()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../scss/elementVar";

.pageContainer {
  padding: 20px;
}
.tagWrap {
  margin-bottom: 20px;
}
.uploadBtn {
  padding: 30px 0;
  width: 150px;
  height: 100px;
  box-sizing: border-box;
  text-align: center;
  color: $--color-info;
  border: 2px dashed $--color-info;
  opacity: 0.7;
  border-radius: 4px;
  transition: all linear 100ms;
}
.uploadBtn:hover {
  opacity: 1;
}
.uploadBtnIcon {
  font-weight: bold;
  font-size: 30px;
}
.uploadBtnText {
  line-height: 10px;
  font-size: 14px;
}
.imgContainer {
  float: left;
  margin: 0 10px 10px 0;
}
</style>
